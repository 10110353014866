import gql from "graphql-tag";

export const DOCUMENT_TYPES = gql`
  query documentType {
    documentTypes {
      id
      name
    }
  }
`;

export const DOCUMENT_CATEGORIES = gql`
  query documentCategories {
    documentCategories {
      id
      name
    }
  }
`;
